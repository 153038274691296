import styled from "styled-components"
import { device } from "../devices"
import { BgImage } from "gbimage-bridge"

const HeroBlock404 = styled(BgImage)`
  align-items: center;
  display: flex;
  min-height: 100vh;
  padding-bottom: 50px;
  padding-top: 50px;

  ::before,
  ::after {
    background-color: ${({ backgroundColor }) =>
      backgroundColor || "var(--color-blanco)"};
    background-blend-mode: multiply;
    transition: background-color 3s !important;
  }

  ${device.tablet} {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  ${device.desktop} {
    padding-top: 150px;
    padding-bottom: 150px;
  }
`

export { HeroBlock404 }
