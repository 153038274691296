import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/Utilities/seo"
import Hero404 from "../components/Hero404"

const NotFoundPage = ({ data }) => {
  const heroImage = getImage(data.heroImage)

  return (
    <>
      <Seo title="404: Not found" />
      <Hero404 image={heroImage}>
        ERROR <strong>404</strong>
      </Hero404>
    </>
  )
}

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "error-404.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 3840
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default NotFoundPage
