import styled from "styled-components"

const Title = styled.h2`
  color: var(--color-blanco);
  font-size: clamp(60px, 15vw, 110px);
  line-height: 1;
  text-align: center;
  margin-bottom: 15px;

  strong {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px;
  }
`

export { Title }
